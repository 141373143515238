import React from "react"

import Layout from "../components/layout"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="My Amazing Gatsby App" />
    <h1>Page not found</h1>
  </Layout>
)

export default NotFoundPage
